@import "settings";
@import "tools/px-em";
@import "tools/map-reach";
@import "tools/fluid-type";
@import "tools/media-query";
@import "tools/strip-unit";
@import "tools/aspec-ratio";
@import "tools/math";
@import "tools/clearfix";

//@import "reset";

@import "vendor/bootstrap_material/mdb";
@import "vendor/bootstrap-select";

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

body {
    -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
    font-family: $default_font;
    font-size:14px;
    height:100%;
    margin:0px;
    padding:0px;
    width:100%;
}
html, body {
	background-color:#E6E6E6;
}

/*
h1,h2,h3,h4,h5,h6 {
    font-family: 'bebas_neuebold', Helvetica, Arial;
} */

.row-100-h {
    min-height: 100vh;
}

.wrapper {
    display: flex;
}

#sidebar {
    position: fixed;
    z-index: 10;
    width: 250px;
    background: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    top: 0px;
    bottom: 0;
    padding: 92px 15px 0;
    margin-left: -250px;
    transition: margin 300ms $ease-in-out-quad;

    &.is-open {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        position: relative;
        margin-left: 0;
    }
}

.navbar .nav-item .nav-link.nav--toggle {
    padding: 0;
    width: auto;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

#content {
    flex-grow: 1;
    min-height: 100vh;
    max-width: 100vw;
    padding: 92px 0 0;

    @include media-breakpoint-up(md) {
        padding: 92px 15px 0;
    }
}

.dropdown-toggle::after {
    display: none;
}
.navbar .dropdown-toggle {
    padding-left: 10px;
    padding-right: 5px;

    .fa {
        font-size: 20px;
        margin-left: 7px;
    }

    .text--username {
        font-size: 13px;
        vertical-align: top;
        margin-top: 6px;
        
        display: none;

        @media (min-width: 576px) {
            display: inline-block;
        }
    }
}

.navbar {
    min-height: 62px;
}
.navbar-brand {
    position: fixed;
    left: 50%;
    margin: 0;
    top: 0;
    transform: translateX(-50%);

    img {
        width: 114px;
        height: 36px;
    }
}
.nav--toggle,
.nav--close,
.nav--delete {
    padding: 0;
    width: 30px;
    font-size: 22px;
}
.nav--close {
    text-align: right;
}

.block-header {
    margin-bottom: 15px;

    h2 {
        margin: 0 !important;
        color: #666 !important;
        font-weight: normal;
        font-size: 16px;
    }
}

.card {
    margin-bottom: 30px;
}

.card-header {
    .card-title {
        margin-bottom: .75rem;
        margin-top: .75rem;
    }
    .dropdown {
        margin-top: 10px;
    }
}

.pagination {
    float: right;
}

.filter .dropdown-toggle {
    margin: 0;
}

.paginate_button {
    display: none;

    &:first-child,
    &:last-child {
        display: list-item;
    }

    @include media-breakpoint-up(md) {
        display: list-item;
    }
}

.photo {
    position: relative;
    display: inline-block;
    margin: 10px 0;

    img {
        display: block;
        margin: 0;
    }
}
.photo-data {
    position: relative;
    display: block;
    padding: 2px 10px;
    color: #fff;
    background: rgba(0,0,0,1);
}

#login {

	.logo {
		width: 200px;
		height: 63px;
    }

    .col {
        max-width: 400px;
    }
    
    .card-header {
        background: #008FDA;
        padding: 40px 0;
    }
}