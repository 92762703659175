$devices: (
  phone: (
    min-width: 320px,
    max-width: 499px
  ),
  largephone: (
    min-width: 500px,
    max-width: 599px
  ),
  tablet7: (
    min-width: 600px,
    max-width: 767px
  ),
  tablet: (
    min-width: 768px,
    max-width: 1024px
  ),
  laptop: (
    min-width: 1025px,
    max-width: 1366px
  ),
  desktop: (
    min-width: 1367px,
    max-width: 1600px
  ),
   largedesktop: (
    min-width: 1601px,
    max-width: 3000px
  )
);


// Name your color variables with "Name that Color"
// http://chir.ag/projects/name-that-color/

$color_main: #232323;
$color_blue: #6bc9f0;
$color_medium: #686464;
$color_lines: #ececec;
$color_light_bg: #fafafa;
$color_red: #ba2427;
$color_more: #58b49b;
$color_link_hover: #686464;


// Unquoted CSS font family name validator
// https://mothereff.in/font-family

$default_font: 'Roboto', Arial, Tahoma, sans-serif;

@font-face {
  font-family: 'bebas_neuebold';
  src: url('../fonts/bebasneue_bold-webfont.eot');
  src: url('../fonts/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
       url('../fonts/bebasneue_bold-webfont.woff') format('woff'),
       url('../fonts/bebasneue_bold-webfont.ttf') format('truetype'),
       url('../fonts/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro Normal.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro NormalItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro Book.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro BookItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Fedra Sans';
  src:    url('../fonts/fedra-sans-pro/FedraSansPro LightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}*/


$ease-in-sine:      cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-in-quad:      cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-in-cubic:     cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-quart:     cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-quint:     cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-in-expo:      cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-circ:      cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-in-back:      cubic-bezier(0.600,-0.280, 0.735, 0.045);

$ease-out-sine:     cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-out-quad:     cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-cubic:    cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-quart:    cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-quint:    cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-expo:     cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-out-circ:     cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-out-back:     cubic-bezier(0.175, 0.885, 0.320, 1.275);

$ease-in-out-sine:  cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-in-out-quad:  cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-in-out-expo:  cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-in-out-circ:  cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-in-out-back:  cubic-bezier(0.680,-0.550, 0.265, 1.550);
